import { FunctionComponent, useContext } from 'react';
import { t } from 'ttag';
import { PageLayout } from 'components/page-layout';
import { LangContext } from 'contexts/LanguageContext';
import { ReactComponent as OverviewIcon } from 'assets/images/tutorials/quickstart.svg';
import { ReactComponent as EntityIcon } from 'assets/images/platform/administration.svg';
import { ReactComponent as AuthIcon } from 'assets/images/platform/mlops.svg';
import { ReactComponent as UAMIcon } from 'assets/images/tutorials/monitor_insights.svg';
import { ReactComponent as PolicyIcon } from 'assets/images/approval.svg';
import { ReactComponent as FeatureIcon } from 'assets/images/tutorials.svg';
import { ReactComponent as ClusterIcon } from 'assets/images/platform/modeling.svg';
import { ReactComponent as NotificationIcon } from 'assets/images/api/quickstart.svg';
import { ReactComponent as NetworkIcon } from 'assets/images/secure.svg';
import { ReactComponent as ReferenceIcon } from 'assets/images/glossary.svg';

const cardsContent = (lang: string) => [
  {
    key: 'overview',
    icon: <OverviewIcon />,
    title: t`Overview`,
    description: t`Review the high-level workflow and important admin concepts`,
    link: t`/${lang}/docs/platform/admin-overview.html`,
  },
  {
    key: 'manage-entities',
    icon: <EntityIcon />,
    title: t`Manage entities`,
    description: t`Manage DataRobot entities, including user accounts, groups, and orgs`,
    link: t`/${lang}/docs/platform/manage-entities/index.html`,
  },
  {
    key: 'manage-the-cluster',
    icon: <ClusterIcon />,
    title: t`Manage the cluster`,
    description: t`Self-managed. Manage the user agreement, application, and more`,
    link: t`/${lang}/docs/platform/manage-cluster/index.html`,
  },
  {
    key: 'authentication',
    icon: <AuthIcon />,
    title: t`Authentication`,
    description: t`Configure DataRobot and an IdP for user authentication via SSO`,
    link: t`/${lang}/docs/platform/sso-ref.html`,
  },
  {
    key: 'network-policy',
    icon: <NetworkIcon />,
    title: t`Network policies`,
    description: t`Limit access to public resources within DataRobot`,
    link: t`/${lang}/docs/platform/network-policy.html`,
  },
  {
    key: 'user-activity-monitor',
    icon: <UAMIcon />,
    title: t`User Activity Monitor`,
    description: t`Monitor user and system activity`,
    link: t`/${lang}/docs/platform/main-uam-overview.html`,
  },
  {
    key: 'deployment-approval-policies',
    icon: <PolicyIcon />,
    title: t`Deployment approval policies`,
    description: t`Configure approval policies for governance and control`,
    link: t`/${lang}/docs/platform/deploy-approval.html`,
  },
  {
    key: 'feature-settings',
    icon: <FeatureIcon />,
    title: t`Feature settings`,
    description: t`View and change feature settings`,
    link: t`/${lang}/docs/platform/user-settings.html`,
  },
  {
    key: 'notification-service',
    icon: <NotificationIcon />,
    title: t`Notification service`,
    description: t`Integrate centralized notifications for change and incident management`,
    link: t`/${lang}/docs/platform/webhooks/index.html`,
  },
  {
    key: 'reference',
    icon: <ReferenceIcon />,
    title: t`Reference`,
    description: t`Reference information for admin-related workflows`,
    link: t`/${lang}/docs/platform/reference/index.html`,
  },
];

const Platform: FunctionComponent = () => {
  const { lang } = useContext(LangContext);
  return (
    <PageLayout
      title={t`Administrator's guide`}
      subtitle={t`The admin section provides materials for administrators to manage their DataRobot accounts`}
      cardsContent={cardsContent(lang)}
      cardsWidth="large"
    />
  );
};

export default Platform;
